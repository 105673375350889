<template>
    <div>
        <template v-if="families_formatted.length == 0">
            <div class="box">
                <span class="text-center">
                    <i>{{ $t('global.no_result') }}</i>
                </span>
            </div>
        </template>
        <template v-else v-for="(family, idx) in families_formatted">
            <div class="box" :key="`horse_product_${idx}`">
                <h3 class="mb-0">
                    <b><router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: family.enfant.horse_id }}">{{ family.enfant.horse_nom | multiCapitalize }}</router-link>
                    </b>
                </h3>
                {{ family.enfant.horse_sexe }}, {{ family.enfant.horse_robe | lowercase }}, {{ $t('horse.par') }} {{ family.pere | multiCapitalize }},
                {{ $t('horse.birth') }} {{ family.enfant.horse_datenaissance }}<span v-if="family.enfant.horse_datemort">, {{ $t('horse.death') }} {{ family.enfant.horse_datemort }}</span>
            </div>
        </template>
    </div>
</template>


<script type="text/javascript">
    import Horse from "@/mixins/Horse.js"

	export default {
		name: "HorseProduct",
        mixins: [Horse],
		props: ['horse_id'],
		data () {
			return {
				families_formatted: [],
                loading: true
			}
        },
        created() {
            this.init_component()
		},
		methods: {
            async init_component() {
				const families = await this.getHorseChildren(this.horse_id)
				this.families_formatted = families.filter(f => !!f.enfant)
                this.loading = false
            }
		}
	}
</script>
